import React, { useEffect, useState } from "react";
import tagManagerEvents from "../utils/GoogleTagManager";
import { ApiName } from "../utils/ApiName";
import LandingPage, { Landing } from "../components/Landing";
import {
  checkOperatorService,
  checkUtmService,
  getFlowService,
  getHeUrl,
  sendOtpService,
  subscribeUserService,
} from "../Services/Subscription";
import {
  ICheckOperatorResp,
  ICheckUtmResp,
  IGetFlowResp,
  ISendOtpResp,
  ISubscribeResp,
} from "../@types/ApiResponse";

type Props = {
  token: string | null;
};

let adNetwork = "";

let priceArr = ["3.8", "3", "3"];
let serviceArr = ["Mobile", "Health", "Health Bundle"];

const LandingScreen = (props: Props) => {
  const [phoneNo, setPhoneNo] = useState("");
  const [otpId, setOtpId] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showError, setShowError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [validNum, setValidNum] = useState(true);
  const [timer, setTimer] = useState(30);
  const [seconds, setSeconds] = useState(30);
  const [isResendClicked, setResendClicked] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [isHidden2, setIsHidden2] = useState(true);
  const [pricePoint, setPricePoint] = useState("");
  const [serviceName, setServiceName] = useState("");

  const [otp, setOtp] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
  });

  const [inputRef, setInputRef] = useState<React.RefObject<HTMLInputElement>[]>(
    [
      React.createRef<HTMLInputElement>(),
      React.createRef<HTMLInputElement>(),
      React.createRef<HTMLInputElement>(),
      React.createRef<HTMLInputElement>(),
    ]
  );

  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const utmSource: string | null = searchParams.get("utm_source");

  let qsPackageId = searchParams.get("package_id")
    ? searchParams.get("package_id")
    : "1";

  useEffect(() => {
    let pasredPackageId = parseInt(qsPackageId as string);
    if (
      Number.isNaN(pasredPackageId) ||
      pasredPackageId > 3 ||
      pasredPackageId < 1
    ) {
      qsPackageId = "1";
    }

    setPricePoint(priceArr[parseInt(qsPackageId as string) - 1]);
    setServiceName(serviceArr[parseInt(qsPackageId as string) - 1]);

    if (isResendClicked) {
      setSeconds(30);
      setResendClicked(false);
    }

    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(interval);
        }
        return prevSeconds > 0 ? prevSeconds - 1 : 0;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isResendClicked]);

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (timer > 0) {
      interval = setTimeout(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearTimeout(interval);
  }, [timer]);

  useEffect(() => {
    if (props.token) {
      // getHe();
      getHE();
      checkUtm();
    }
  }, [props.token]);
  // const getHe = () => {
  //   fetch("http://115.42.72.186:8088/Jazz_HE/")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //       if (data?.errorCode === "0" && data?.MSISDN) {
  //         let _number = data?.MSISDN;
  //         setPhoneNo(_number.slice(-10));
  //         if (utmSource) {
  //           checkUtm(utmSource);
  //         }
  //         // getFlow(data?.MSISDN);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("something went wrong", error);
  //     });
  // };
  const getHE = async () => {
    try {
      let response = await getHeUrl();
      console.log(response);
      if (response.status.toLowerCase() === "success") {
        getFlow(response.msisdn);
      }
    } catch (error) {
      setShowError(true);
      setErrorMsg("Something went wrong");
      console.log("handleGetFlow Error: ", error);
    }
  };

  const getFlow = async (msisdn: any) => {
    try {
      let _qsPackageId = "1";
      if (qsPackageId === "1" || qsPackageId === "2" || qsPackageId === "3") {
        _qsPackageId = qsPackageId;
      }
      let data = {
        utm_source: utmSource,
        packageId: _qsPackageId,
        msisdn,
      };
      let response: IGetFlowResp | null = await getFlowService(
        data,
        props.token
      );
      if (response?.code === 0) {
        if (
          response.result.isSpecialFlow === true &&
          response.result.msisdn !== "" &&
          response.result.otp !== ""
        ) {
          let phoneNumber = response.result.msisdn;
          let _qsPackageId = "1";
          if (
            qsPackageId === "1" ||
            qsPackageId === "2" ||
            qsPackageId === "3"
          ) {
            _qsPackageId = qsPackageId;
          }
          let data_ = {
            msisdn: phoneNumber.slice(-10),
            pin: response.result.otp,
            packageId: _qsPackageId,
            utm_source: utmSource,
            otpId: response.result.otpId,
          };

          let Api_name = response.result.fastFlow
            ? ApiName.ffSubscribe
            : ApiName.subscribe;

          subscribeUser(Api_name, data_);
        } else if (
          response.result.isSpecialFlow === false &&
          response.result.msisdn
        ) {
          // 4g user without special flow
          let phoneNumber = response.result.msisdn;
          setPhoneNo(phoneNumber.slice(-10));
          sendOTP(phoneNumber.slice(-10), true);

          tagManagerEvents("heUser", utmSource);
          if (response.result.signIn) {
            setIsHidden2(false);
          }
        } else {
          // wifi user
          tagManagerEvents("wifiUser", utmSource);
        }
      } else {
        setShowError(true);
        setErrorMsg("Something went wrong");
      }
    } catch (error) {
      setShowError(true);
      setErrorMsg("Something went wrong");
      console.log("handleGetFlow Error: ", error);
    }
  };

  const handlePhoneNoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (!value || (value.length <= 10 && value.match(/^3\d{0,9}$/))) {
      setShowError(false);
      setValidNum(true);
      setPhoneNo(e.target.value);
      setTimer(30);
      setOtp({
        one: "",
        two: "",
        three: "",
        four: "",
      });
      if (value.length === 10) {
        checkOperator(e.target.value);
      }
    } else {
    }
  };

  const checkOperator = async (phoneNumber: string) => {
    try {
      let data = {
        msisdn: phoneNumber,
      };
      let response: ICheckOperatorResp | null = await checkOperatorService(
        data,
        props.token
      );
      if (response.code === 0) {
        if (response.network.toLowerCase() === "other network") {
          setValidNum(false);
          setShowError(true);
          setErrorMsg(
            "Service is only for Zong customers. Kindly enter a working Zong number."
          );
        } else {
          sendOTP(phoneNumber, false);
        }
      } else {
        setValidNum(false);
        setShowError(true);
        setErrorMsg("Something went wrong, Please try again.");
      }
    } catch (err) {
      setValidNum(false);
      setShowError(true);
      setErrorMsg("Something went wrong, Please try again.");
    }
  };

  const sendOTP = async (phoneNo: string, autoFill: boolean) => {
    let _qsPackageId = "1";
    if (qsPackageId === "1" || qsPackageId === "2" || qsPackageId === "3") {
      _qsPackageId = qsPackageId;
    }
    let data = {
      msisdn: phoneNo,
      packageId: _qsPackageId,
    };
    try {
      let response: ISendOtpResp | null = await sendOtpService(
        data,
        props.token
      );
      if (response.code === 0) {
        if (autoFill) {
          let _otp = response.otp.split("");
          let tempOTP = { ...otp };
          tempOTP.one = _otp[0];
          tempOTP.two = _otp[1];
          tempOTP.three = _otp[2];
          tempOTP.four = _otp[3];
          setOtp(tempOTP);
        }
        setOtpId(response.otpId);
      } else {
        setShowError(true);

        setErrorMsg("Unable to send OTP, Please try again.");
      }
    } catch (err) {
      setShowError(true);

      setErrorMsg("Something went wrong, Please try again.");
    }
  };

  const onSubscribePressed = () => {
    let checkOTP = otp.one + otp.two + otp.three + otp.four;
    let _qsPackageId = "1";
    if (qsPackageId === "1" || qsPackageId === "2" || qsPackageId === "3") {
      _qsPackageId = qsPackageId;
    }
    let data = {
      msisdn: phoneNo,
      pin: checkOTP,
      packageId: _qsPackageId,
      utm_source: utmSource,
      otpId: otpId,
    };
    subscribeUser(ApiName.subscribe, data);
  };
  //Utm
  const checkUtm = async () => {
    try {
      let response: ICheckUtmResp = await checkUtmService(props.token);
      if (response?.code === 0) {
        {
          response.result.map((e: any) => {
            if (utmSource === e.sf_utm) {
              console.log("ad_network", e.ad_network);
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  // --------------- APIs ---------------
  const subscribeUser = async (endpoint: string, data: any) => {
    try {
      setShowLoader(true);
      let response: ISubscribeResp = await subscribeUserService(
        props.token,
        endpoint,
        data
      );
      if (response.code === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          tagManagerEvents("subscribe", "tiktok" + qsPackageId);
        } else if (adNetwork.toLowerCase() === "google") {
          console.warn("bbb");
          tagManagerEvents("subscribe", "optimus");
        } else {
          console.warn("ccc");
          tagManagerEvents("subscribe", utmSource);
        }
        setIsHidden(false);
        setShowLoader(false);
      } else if (response.code === 1) {
        tagManagerEvents("subscribeFailed", utmSource);
        setShowLoader(false);
        setShowError(true);
        setErrorMsg(
          "You're Already Subscribed! Please call 7030 for more details."
        );
      } else {
        tagManagerEvents("subscribeFailed", utmSource);
        setShowLoader(false);
        setShowError(true);
        setErrorMsg("Unable to subscribe,Please call 7030 for more details.");
      }
    } catch (error) {
      tagManagerEvents("subscribeFailed", utmSource);
      setShowLoader(false);
      setShowError(true);
      setErrorMsg("Unable to subscribe,Please call 7030 for more details.");
    }
  };

  const handleChangeOTP = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let otpEntered = e.target.value;
    setShowError(false);

    if (!isNaN(otpEntered as any) && otpEntered !== " ") {
      setOtp({
        ...otp,
        [e.target.name]: otpEntered,
      });
      if (e.target.value.length === 1) {
        const nextInput = inputRef[index + 1];
        if (nextInput?.current) {
          nextInput.current.focus();
        }
      }
    } else {
      setShowError(true);
      setErrorMsg("Please enter a valid OTP");
    }
  };
  // backspace button
  const handleBackspace = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ): void => {
    if (e.keyCode === 8) {
      setShowError(false);
      const target = e.target as HTMLInputElement;
      if (target.value.length === 0 && index !== 0) {
        (inputRef[index - 1].current as HTMLInputElement).focus();
      }
    }
  };

  const onResendOtpPressed = async () => {
    tagManagerEvents("resendOTP", utmSource);
    setTimer(30);
    setResendClicked(true);
    setOtp({
      one: "",
      two: "",
      three: "",
      four: "",
    });
    sendOTP(phoneNo, false);
  };

  const handleOverLay = () => {
    setIsHidden(true);
  };
  const handleOverLay2 = () => {
    setIsHidden2(true);
  };

  return (
    <LandingPage
      showLoader={showLoader}
      errorMsg={errorMsg}
      showError={showError}
      onSubscribePressed={onSubscribePressed}
      phoneNo={phoneNo}
      otp={otp}
      handleChangeOTP={handleChangeOTP}
      handleBackspace={handleBackspace}
      onResendOtpPressed={onResendOtpPressed}
      inputRef={inputRef}
      handlePhoneNoChange={handlePhoneNoChange}
      seconds={seconds}
      validNum={validNum}
      isHidden={isHidden}
      handleOverLay={handleOverLay}
      package_id={qsPackageId}
      isHidden2={isHidden2}
      handleOverLay2={handleOverLay2}
      pricePoint={pricePoint}
      serviceName={serviceName}
    />
  );
};

export default LandingScreen;
