import "../styles/Terms.css";

function PrivacyPolicy() {
  return (
    <div className="privacy_policy_page">
      <h2>Privacy Policy</h2>
      <div style={{ background: "rgba(0,0,0,0.8)", padding: "20px" }}>
        <div>
          <p>
            This privacy policy clarifies and declares the legal terms and
            conditions of this site and the information exchanged between the
            website owners and the users.
          </p>
          <p>
            In regards to providing services to our users, we gather the
            necessary data some basic personal information of the user. In order
            to legally utilize the services and offers of this website, you must
            follow and agree to this privacy policy. In case if you disagree
            with this policy, you must stop using the services of this website.
          </p>
          <p>
            <span style={{fontWeight: "600", fontSize: "18px"}}>
              Personal Data we collect:
            </span>
          </p>
          <p>
            In order to completely enjoy our services, you need to sign up with
            the website. At the time of signup, the site will ask for some
            personal data and information about you. This data is not utilized
            anywhere and is stored to improve the user experience and security
            of the website.
          </p>
          <p>
            The personal data we collect is only to ensure the accessibility of
            our services to you. No data or personal information whatsoever will
            be collected without your knowledge. The personal data we collect
            might be your name, CNIC, email address, and contact number.
            However, this data will not include the passport number, drivers’
            license number, bank card numbers, or any other registration numbers
            that might be provided to you by a third party.
          </p>
          <p>
            You will require to enter a password to create a private account.
            Make sure to protect the password at all costs. That password
            shouldn’t be provided or exposed to any other entity or used with
            any third-party services.
          </p>
          <p>
            <span style={{fontWeight: "600", fontSize: "18px"}}>
              Encryption:
            </span>
          </p>
          <p>
            To ensure maximum security and avoid any possible security breach,
            we may encrypt your data and password so that you can use the
            services without any interruption.
          </p>
          <p>
            The encryption of the data is only to ensure maximum security and
            avoid any security breach. Your data and personal information are
            used to provide you with our best premium services. In order to use
            our services, you are advised to accept our privacy policy.
          </p>
          <p>
            The website can change the encryption policy without prior notice to
            every user. As our user, you grant us the privilege to change the
            encryption policy anytime to ensure maximum security.
          </p>
          <p>
            <span style={{fontWeight: "600", fontSize: "18px"}}>
              Personal information we may use:
            </span>
          </p>
          <p>
            We are destined to provide the best user experience for our users.
            We utilize some personal information of the users like name and
            contact number for notifications and consultation. You might receive
            our updates, notifications, and information on your contact number
            as well as email address that you provide at the time of signup.
          </p>
          <p>
            Every information is provided by the user at the time of signup. We
            don’t use any information and personal data of use other than for
            notifications and providing the best user experience. No data is
            revealed/ exposed/ used to any third-party service provided without
            the permission of the user. The data provided by the users is always
            safeguarded and not used in any other service and platform other
            than this website. However, in case of any security breach or data
            loss, the website will not be held liable to data loss.
          </p>
          <p>
            <span style={{fontWeight: "600", fontSize: "18px"}}>
              Queries and Complaints:
            </span>
          </p>
          <p>
            We seek to meet the highest standards of providing services and
            collecting/ using user’s personal data. If you have any questions
            regarding the services and content of this website, please contact
            our customer services department. Leave us a message regarding your
            issues and our team will get back to you with the most feasible
            solution.
          </p>
          <p>
            <span style={{fontWeight: "600", fontSize: "18px"}}>
              Content Creation/ Copyrights:
            </span>
          </p>
          <p>
            All of the content that is uploaded on the website is copyrighted
            and is a property of this website. We are responsible for every
            content that is uploaded on this website and we ensure that all of
            the content is to provide better services to our users.
          </p>
          <p>
            We allow our users to create, manage and upload some content on the
            website. However, the user will be held responsible for the content
            that will be uploaded by the user. In case any user’s content gets
            reported/ banned for being unethical/ unnatural, the website
            management will not be held responsible and will be accused in any
            case. Users must take responsibility of their personal information
            and the content they are allowed to create on this website. In case
            of any security breach or loss of data, no data shall be provided to
            users in any case if except necessary.
          </p>
          <p>
            All the users of this website must agree with the privacy policy. We
            look forward to providing the best services to our users and
            increase the user experience.
          </p>
          <p>
            This website can change/update this privacy policy at any time
            without prior notice to the users of our services.
          </p>
          <p>
            If you have questions regarding the privacy policy of WebDoc, please
            contact{" "}
            <span style={{fontWeight: "600", fontSize: "18px"}}>
              <a>
                <strong>support@webdoc.com.pk</strong>
              </a>
            </span>
          </p>
          <p>
            This website uses “cookies” to improve the user experience. The
            Cookie technology helps us to track your navigation and search
            information. For further information about our cookie policy, please
            refer to our “Terms and Conditions”.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
