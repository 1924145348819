import React, { useEffect, useState } from "react";
import { ApiName } from "../utils/ApiName";
import tagManagerEvents from "../utils/GoogleTagManager";
import Awareness from "../components/Awareness";
import {
  awarenessFlowService,
  checkUtmService,
  getHeUrl,
} from "../Services/Subscription";
import { IAwarenessFlowResp, ICheckUtmResp } from "../@types/ApiResponse";

type Props = {
  token: string | null;
};

let adNetwork = "";

const AwarenessScreen = (props: Props) => {
  const [isHidden, setIsHidden] = useState(true);

  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const utmSource: any = searchParams.get("utm_source");
  let qsPackageId = searchParams.get("package_id")
    ? searchParams.get("package_id")
    : "1";

  useEffect(() => {
    let pasredPackageId = parseInt(qsPackageId as string);
    if (
      Number.isNaN(pasredPackageId) ||
      pasredPackageId > 3 ||
      pasredPackageId < 1
    ) {
      qsPackageId = "1";
    }
    if (props.token) {
      getHE();
      checkUtm();
      // awarenessFlow();
    }
  }, [props.token]);

  // const getHe = () => {
  //   fetch("http://115.42.72.186:8088/Jazz_HE/")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //       if (data?.errorCode === "0" && data?.MSISDN) {
  //         if (utmSource) {
  //           checkUtm(utmSource);
  //         }
  //         awarenessFlow(utmSource, data.MSISDN);
  //       } else {
  //         window.location.replace("http://jazz.webdoc.com.pk/landing");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("something went wrong", error);
  //       window.location.replace("http://jazz.webdoc.com.pk/landing");
  //     });
  // };

  const checkUtm = async () => {
    try {
      let response: ICheckUtmResp = await checkUtmService(props.token);
      if (response.code === 0) {
        {
          response.result.map((e: any) => {
            if (utmSource === e.sf_utm) {
              adNetwork = e.ad_network;
            }
          });
          console.log("ad_network", adNetwork);
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };
  const getHE = async () => {
    try {
      let response = await getHeUrl();
      console.log(response);
      if (response.status.toLowerCase() === "success") {
        awarenessFlow(response.msisdn);
      } else {
        window.location.replace("http://zong.webdoc.com.pk/landing");
      }
    } catch (error) {
      window.location.replace("http://zong.webdoc.com.pk/landing");
      console.log("handleGetFlow Error: ", error);
    }
  };

  const awarenessFlow = async (msisdn: any) => {
    try {
      let _qsPackageId = "1";
      if (qsPackageId === "1" || qsPackageId === "2" || qsPackageId === "3") {
        _qsPackageId = qsPackageId;
      }
      let data = {
        utm_source: utmSource,
        packageId: _qsPackageId,
        msisdn,
      };
      let response: IAwarenessFlowResp = await awarenessFlowService(
        data,
        props.token
      );
      if (response.code === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          tagManagerEvents("subscribe", "tiktok" + qsPackageId);
        } else if (adNetwork.toLowerCase() === "google") {
          console.warn("bbb");
          tagManagerEvents("subscribe", "optimus");
        } else {
          console.warn("ccc");
          tagManagerEvents("subscribe", utmSource);
        }
        setIsHidden(false);
      } else {
        window.location.replace("http://zong.webdoc.com.pk/landing");
      }
    } catch (error) {
      console.log(error);
      window.location.replace("http://zong.webdoc.com.pk/landing");
    }
  };

  const handleOverLay = () => {
    setIsHidden(true);
  };

  return <Awareness handleOverLay={handleOverLay} isHidden={isHidden} />;
};

export default AwarenessScreen;
